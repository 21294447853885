import axios from 'axios';
import { BackendUrl, REACT_APP_API, REACT_APP_ASSETS } from '@env'

const ajax = axios.create({
  baseURL: REACT_APP_API ? REACT_APP_API : BackendUrl ? BackendUrl : 'https://dev.api.hubita.co.uk/',
  headers: {
    'x-requested-with': 'XMLHttpRequest',
    'request-type': 'application/json',
  },
  timeout: 60 * 1000,
});

export const httpUpload = axios.create({
  baseURL: REACT_APP_ASSETS ? REACT_APP_ASSETS : 'https://assets.hubita.co.uk/',
  headers: {
    'x-requested-with': 'XMLHttpRequest',
    'Content-type': `application/json`,
    'request-type': 'application/json',
  },
  timeout: 60 * 1000,
});
export default ajax;

export const CancelToken = () => axios.CancelToken.source();

export const setAuthHeaders = (context) => {
  ajax.defaults.headers.common.Authorization = `Bearer ${context}`;
  httpUpload.defaults.headers.common.Authorization = `Bearer ${context}`;
};

export const { isCancel } = axios;

/**
 * 
let user: User = null;
try {
  const { data } = await ajax.get('/user?ID=12345');
  user = data.userDetails;
} catch (error) {
    handleUnexpectedError(error);
  }
}
*/
